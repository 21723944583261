import { initializeCustomer } from './biz';
import { initializeIntegrations } from './integrations';
import { hotReloadPageOnSDKUpdate, isDevelopment, logger, promiseTry } from './lib';

export * from './index';

const triggerOneTextLoad = () : void => {
    void logger.info('onetext_sdk_load').flush();

    const existingOneTextLoad = window.onLoadOneText ??= [];

    const load = (...loaders : ReadonlyArray<OneTextLoader>) : number => {
        loaders.forEach(loader => {
            setTimeout(() => {
                void logger.info('onetext_child_sdk_load', {
                    name: loader.name
                }).flush();

                if (typeof loader === 'function') {
                    void loader();
                } else {
                    void loader.loader();
                }
            }, 0);
        });

        return loaders.length;
    };

    existingOneTextLoad.push = load;

    load(...existingOneTextLoad);
};

void promiseTry(async () => {
    await Promise.all([
        initializeIntegrations(),
        initializeCustomer(),
        isDevelopment()
            ? hotReloadPageOnSDKUpdate()
            : undefined,
        triggerOneTextLoad()
    ]);
});
