/** @jsx h */

import type { VNode } from 'preact';

import { h } from 'preact';

import { Z_INDEX_MAX } from '../constants';

type DialogProps = {
    children : VNode,
};

export const Dialog = ({
    children
} : DialogProps) : VNode => {
    if (
        typeof HTMLDialogElement !== 'function' ||
        !('showModal' in HTMLDialogElement.prototype)
    ) {
        return children;
    }

    return (
        <dialog
            style={
                {
                    zIndex: Z_INDEX_MAX
                }
            }
            ref={
                el => {
                    if (typeof el?.showModal === 'function') {
                        el.showModal();
                    }
                }
            }
        >
            { children }
        </dialog>
    );
};
